import React, {
  useState,
  useRef,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { Autocomplete } from '@react-google-maps/api'
import styles from './dynamicInput.module.scss'
import TripAdvisorDebounce from '../TripAdvisorDebounce'

interface DynamicInputProps {
  name: string
  nestedRef?: React.LegacyRef<Autocomplete> | undefined
  setValues: Dispatch<SetStateAction<{ [x: string]: string[] }>>
  values: string[]
}

const DynamicInput: React.FC<DynamicInputProps> = ({
  nestedRef,
  values,
  setValues,
  name,
}) => {
  const [inputs, setInputs] = useState<string[]>(values.length ? values : [''])

  const autocompleteRefs = useRef<(google.maps.places.Autocomplete | null)[]>(
    []
  )
  const inputRefs = useRef<HTMLInputElement[]>([])
  const handlePlaceChanged = (index: number) => {
    const autocomplete = autocompleteRefs.current[index]
    if (autocomplete) {
      const place = autocomplete.getPlace()
      if (place && place.place_id) {
        const newInputs = [...inputs]
        newInputs[index] = place.place_id
        setInputs(newInputs)
        setValues((prev) => ({
          ...prev,
          [name]: newInputs,
        }))
      }
    }
  }

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (event.key === 'Enter') {
      if (inputs[index].trim() !== '') {
        setInputs((prev) => [...prev, ''])
      }
      setTimeout(() => {
        const newIndex = index + 1
        if (inputRefs.current[newIndex]) {
          inputRefs.current[newIndex].focus()
        }
      }, 0)
    }
  }

  const handleSuggestionClick = (index: number) => {
    if (inputs[index].trim() !== '') {
      setInputs((prev) => [...prev, ''])
    }
    setTimeout(() => {
      const newIndex = index + 1
      if (inputRefs.current[newIndex]) {
        inputRefs.current[newIndex].focus()
      }
    }, 0)
  }

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const newInputs = [...inputs]
    newInputs[index] = event.target.value
    setInputs(newInputs)
    setValues((prev) => {
      return { ...prev, [name]: newInputs }
    })
  }

  const handleDelete = (index: number) => {
    if (inputs.length === 1) {
      setInputs([''])
      setValues((prev) => {
        return { ...prev, [name]: [...[]] }
      })
    } else {
      const newInputs = inputs.filter((_, i) => i !== index)
      setInputs(newInputs)
      setValues((prev) => {
        return { ...prev, [name]: newInputs }
      })
    }
  }

  const handleTripAdvisorPlaceChange = (
    index: number,
    placeId: string | undefined
  ) => {
    if (!placeId) return

    const newInputs = [...inputs]
    newInputs[index] = placeId
    setInputs(newInputs)
    setValues((prev) => ({
      ...prev,
      [name]: newInputs,
    }))
  }

  useEffect(() => {
    setInputs(values.length ? values : [''])
  }, [values])

  return (
    <>
      <div className={styles.dynamicInputsContainer}>
        {inputs.map((input, index) => (
          <div
            className={styles.inputContainer}
            key={index}
            style={{ position: 'relative' }}
          >
            {name === 'Google Reviews' ? (
              <Autocomplete
                onLoad={(autocomplete) =>
                  (autocompleteRefs.current[index] = autocomplete)
                }
                onPlaceChanged={() => {
                  handlePlaceChanged(index)
                }}
                ref={nestedRef}
              >
                <input
                  className={styles.input}
                  onChange={(e) => handleChange(e, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  placeholder="Search for places"
                  ref={(el) => el && (inputRefs.current[index] = el)}
                  type="text"
                  value={input}
                />
              </Autocomplete>
            ) : name === 'Tripadvisor' ? (
              <div style={{ position: 'relative' }}>
                <input
                  className={styles.input}
                  onChange={(e) => handleChange(e, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  placeholder="Search for places"
                  ref={(el) => el && (inputRefs.current[index] = el)}
                  type="text"
                  value={input}
                />
                <TripAdvisorDebounce
                  handleSuggestionClick={() => handleSuggestionClick(index)}
                  inputRef={inputRefs.current[index]}
                  onSelect={(placeId: string | undefined) =>
                    handleTripAdvisorPlaceChange(index, placeId)
                  }
                  searchQuery={values[index]}
                />
              </div>
            ) : (
              <>
                <input
                  className={styles.input}
                  onChange={(e) => handleChange(e, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  placeholder="Enter Link"
                  ref={(el) => el && (inputRefs.current[index] = el)}
                  type="text"
                  value={input}
                />
              </>
            )}
            <button
              className={styles.deleteButton}
              onClick={() => handleDelete(index)}
              type="button"
            >
              <FontAwesomeIcon
                className={styles.sidebarMenuItemIcon}
                color="#86949D"
                icon={faTrash}
              />
            </button>
          </div>
        ))}
      </div>
      <span className={styles.newFieldLabel}>
        Press Enter to add new link field
      </span>
    </>
  )
}

export default DynamicInput
